@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;


.react-json-view {
  word-break: break-all;
}

.custom-search-bar {
  display: flex;
  justify-content: space-between;
}

.custom-search-bar-items {
  padding-left: 5px;
  padding-right: 5px;
}

.custom-search-bar-input {
  width: 92% !important;
  padding-left: 5px;
  padding-right: 5px;
}

.inp {
  white-space: nowrap !important;
}

.custom-home__header {
  background-color: awsui.$color-background-home-header;
}

.custom-home__header-title {
  color: awsui.$color-text-home-header-default;
}

.custom-home__category,
.custom-home__header-sub-title {
  color: awsui.$color-text-home-header-secondary;
}

.custom-select {
  width: 150px;
  padding-left: 10px;
  padding-right: 10px;
}

.dark-mode-toggle-wrapper span {
  color: white !important;
}

.publish-status-button {
  border-block-end: 1px dashed awsui.$color-text-status-error !important;
  border-radius: 0 !important;
}

.in-dlq-button {
  border-block-end: 1px dashed awsui.$color-text-status-warning !important;
  border-radius: 0 !important;
}


.ace-cloud_editor_dark {
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
}

.awsui_dialog_1d2i7_18st5_165 {
  max-width: 1500px !important;
}

.mode-toggle-wrapper {
  margin-right: 3rem;
  display: flex;
  gap: 1rem;
  align-items: center;

  &__icon {
    margin-top: 3px;
  }
}

header {
  background: #232F3E !important;
}

// Shrink JSON diff tool line number font size
.json-diff-viewer td.line-number {
  font-size: 11px !important;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .awsui-app-layout {
    flex: 1;

    &__content {
      height: 100%;
    }
  }
}

#dashboardContainer {
  background: var(--color-background-container-content);
  border-radius: var(--border-radius-container);
}

.input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  order: 0;
  flex-grow: 10;
  margin-inline-end: 0;
  margin-block-end: calc(-1 * #{awsui.$space-scaled-m});

  > *:not(:empty) {
    margin-inline-end: awsui.$space-scaled-m;
    margin-block-end: awsui.$space-scaled-s;
  }
}

.input-filter {
  order: 0;
  flex-grow: 6;
  inline-size: auto;
  max-inline-size: 300px;
  min-inline-size: 200px;
}

.select-filter {
  min-inline-size: 80px;
  flex-grow: 2;
  inline-size: auto;
}

.filtering-results {
  display: block;
  padding-block-end: calc(1px + #{awsui.$space-scaled-xxs});
  color: awsui.$color-text-form-default;
  align-self: center;
}

@media (max-width: 1152px) {
  .input-container {
    margin-inline-end: calc(-1 * #{awsui.$space-scaled-m});
  }

  .select-filter {
    max-inline-size: none;
  }

  .input-filter {
    inline-size: 100%;
    max-inline-size: none;
  }
}